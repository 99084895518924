import React from 'react';
import { navigate } from 'gatsby';
// import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import useSiteMetadata from '../hooks/useSiteMetadata';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
    marginBottom: 0,
  },
  frame: {
    display: 'block',
    width: '100%',
    height: 'calc(100vh - 107px)',
    border: 0,
  },
}));

const CustomIframe = ({ src }) => {
  const { docHome } = useSiteMetadata();

  const handleFrameLoad = React.useCallback(event => {
    const localPath = event.target.contentWindow.location.href.replace(docHome, '');
    // eslint-disable-next-line no-console
    console.log(`/documentation${localPath}`);
    navigate(`/documentation${localPath}`);
  }, [docHome]);

  return (
    <iframe
      src={src}
      title="doc"
      style={{
        display: 'block',
        width: '100%',
        height: 'calc(100vh - 107px)',
        border: 0,
      }}
      onLoad={handleFrameLoad}
    />
  );
};

const Documentation = ({ '*': docPage }) => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const { docHome } = useSiteMetadata();

  return (
    <Layout translationsAvailable footer={false} className={classes.container}>
      <CustomIframe src={`${docHome}/${docPage}`} className={classes.frame} />
    </Layout>
  );
};

export default Documentation;
